import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {
  AiFillInstagram,
} from 'react-icons/ai';

import { FaDiscord, FaFacebookF, FaLinkedinIn } from 'react-icons/fa';

function Footer() {
  return (
    <Container fluid className='footer'>
      <Row>
        <Col md="4" className="footer-copywright"></Col>
        <Col md="4" className="footer-copywright">
          <h3>Curated by SUCSS's Tech Team</h3>
        </Col>
        <Col md='4' className='footer-body'>
          <ul className='footer-icons'>
            <li className='social-icons'>
              <a
                href="https://discord.gg/gUGqmt4Gqf"
                style={{ color: 'white' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaDiscord />
              </a>
            </li>
            <li className='social-icons'>
              <a
                href="https://www.facebook.com/profile.php?id=61552628758945"
                style={{ color: 'white' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF />
              </a>
            </li>
            <li className='social-icons'>
              <a
                href="https://www.linkedin.com/company/usyd-csec"
                style={{ color: 'white' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </li>
            <li className='social-icons'>
              <a
                href="https://www.instagram.com/usyd_cybersoc/"
                style={{ color: 'white' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillInstagram />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
