import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Particle from '../Particle';
import trans from '../../Assets/trans.png';
// import Toolstack from './Toolstack';
// import Github from './Github';
// import Techstack from './Techstack';
// import Aboutcard from './AboutCard';

function About() {
  return (
    <Container fluid className='about-section'>
      <Particle />
      <Container>
        <Row style={{ justifyContent: 'center', padding: '10px' }}>
          <Col
            md={7}
            style={{
              justifyContent: 'center',
              paddingTop: '30px',
              paddingBottom: '50px',
            }}
          >
            {/* <Aboutcard /> */}
          </Col>

          <h1
            style={{
              fontSize: '2.1em',
              paddingBottom: '20px',
              marginRight: '40px',
            }}
          >
            Introducing our 2024 <strong className="blue">Executives</strong>
          </h1>

          <Col md={8} style={{ paddingBottom: '50px' }}>
            <img src={trans} alt="about" className="img-fluid" />
          </Col>
        </Row>
        {/* <h1 className='project-heading'>
          Professional <strong className='purple'>Skillset </strong>
        </h1>
        <Techstack />

        <h1 className='project-heading'>
          <strong className='purple'>Tools</strong> I use
        </h1>
        <Toolstack />

        <Github /> */}
      </Container>
    </Container>
  );
}

export default About;
