import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import Particle from '../Particle';
import { Helmet } from 'react-helmet';
import usulogo from '../../Assets/usulogo.png';
import React, { useEffect } from 'react';
import './contact.css';
import { FaFacebookF, FaDiscord, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
function Contact() {
  useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://cdn.lightwidget.com/widgets/lightwidget.js';
      script.async = true;
      document.head.appendChild(script);

      return () => {
          document.head.removeChild(script);
      };
  }, []);

  let t1 = 'http://';
  let currentDomain = window.location.host;
  currentDomain = t1 + currentDomain + '/contact';

  return (
    <Container fluid className='contact-section'>
       <Helmet>
        <style>{'body { background-color: red; !important}'}</style>
      </Helmet>
	<Row style={{ justifyContent: 'center' }}>
          {/* Link to Cyber Security Society */}
          <Col md={12} style={{
            textAlign: 'center',
            marginTop: '20px', marginBottom: '40px'
          }}>
            <h1 className='contact-heading'>
              Our <span style={{ color: '#fce200' }}>USU </span>
              <strong className='blue'>Page</strong>
            </h1>
            <p class="contact-content">
              Our society hosts a plethora of events,
              catchups, and industry talks.
              <br></br>
              To register and become a part of our society, click this button!
            </p>
            <a
              href="https://usu.edu.au/clubs/sydney-university-cyber-security-society/"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-dark"
            >
              <img
                src={usulogo}
                alt="USU Logo"
                style={{ width: '250px' }}
              />
            </a>
            <br></br>
            <br></br>
          </Col></Row>
      <Particle />
          <Container>
              {/* Instagram Feed Embed */}
{/*
              <Row className="justify-content-center">
                  <Col md={12}>
                      <h2 className='contact-heading'>
                          Connect with us on <strong className='blue'>Social Media</strong>
                      </h2>
                      <p className="contact-content">
                          Follow us on Instagram to stay updated with our latest events and activities.
                      </p>
                      <div dangerouslySetInnerHTML={{
                          __html: '<iframe src="//lightwidget.com/widgets/941f2e963d755a56969f1ebb611ef81b.html" scrolling="no" allowtransparency="true" class="lightwidget-widget" style="width:100%;border:0;overflow:hidden;"></iframe>',
                      }} />
                  </Col>
              </Row>
	      */}

              <br></br>
              <br></br>
              {/* Social Media Links */}
                <Row className="justify-content-center social-media-links">
                    <div className="social-box facebook">
                        <a href="https://www.facebook.com/people/USYD-Cybersecurity-Society/61552628758945/" target="_blank" rel="noopener noreferrer">
                        Facebook <FaFacebookF size={28} />
                        </a>
                    </div>
                    <div className="social-box twitter">
                        <a href="https://discord.com/invite/gUGqmt4Gqf" target="_blank" rel="noopener noreferrer">
                        Discord <FaDiscord size={28} />
                        </a>
                    </div>
                    <div className="social-box linkedin">
                        <a href="https://www.linkedin.com/company/usyd-csec/" target="_blank" rel="noopener noreferrer">
                        LinkedIn <FaLinkedinIn size={28} />
                        </a>
                    </div>
                    <div className="social-box instagram">
                        <a href="https://www.instagram.com/usyd_cybersoc/" target="_blank" rel="noopener noreferrer">
                        Instagram <FaInstagram size={28} />
		</a>
                    </div>
                </Row>
              <br></br>
              <br></br>
        <Row style={{ justifyContent: 'center' }}>
          <Col md={7}>
            {/* Contact Description */}
            <h1 class='contact-heading'>
              Get In Touch With <strong className='blue'>Us</strong>
            </h1>
            <p class="contact-content">
              Whether you're interested in becoming a sponsor or joining our
              society, we're here to answer your questions. Fill out the form
              below, and we will reach out to you shortly.
            </p>
          </Col>

          {/* Contact Form */}
          <Col md={8} style={{ paddingBottom: '30px' }}>
            <Card className='form-card'>
              <Form
                class="contact-form"
                action="https://formsubmit.co/27bb2c9f8df67e404849d5f60284aec2"
                method="POST"
              >
                <input type="hidden" name="_captcha" value="true" />
                <input type="hidden" name="_next" value={currentDomain} />
                {/* Sponsor Form */}
                <Form.Group controlId='sponsorForm'>
                  {/* <Form.Label>Your Name</Form.Label> */}
                  <Form.Control type='text' placeholder='Your Name' name='name' />
                  {/* <Form.Label>Your Email</Form.Label> */}
                  <br />
                  <Form.Control
                    type="email"
                    placeholder="Your Email"
                    name="email"
                  />
                  {/* <Form.Label>Company Name</Form.Label> */}
                  <br />
                  <Form.Control
                    type="text"
                    placeholder="Company Name"
                    name="c_name"
                  />
                  {/* <Form.Label>Your Message</Form.Label> */}
                  <br />
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Your Message"
                    name="message"
                  />
                  <br />
                  <Button variant='primary' type='submit' value='send'>
                    Submit
                  </Button>
                </Form.Group>
              </Form>
            </Card>
          </Col>
        </Row>
	</Container>
      </Container>
  );
}


export default Contact;
