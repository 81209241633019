import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import homeLogo from '../../Assets/hackeranimation.png';
import Particle from '../Particle';
import Type from './Type';
import myImg from '../../Assets/teampic.JPG';

import dewawebLogo from '../../Assets/dewaweb.png';
import dewaguardLogo from '../../Assets/dewaguard.png';
import usuLogo from '../../Assets/usu.png';

import Tilt from 'react-parallax-tilt';
import { FaDiscord, FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { faDiscord, faInstagram, faLinkedinIn, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons';

import './style.css';

function FindUsShortCut() {
  const [showSocialIcons, setShowSocialIcons] = useState(false);

  const show = () => {
    setShowSocialIcons(!showSocialIcons);
  };

  return (
      <div className={`find-us-shortcut ${showSocialIcons ? 'showSocialIcons' : ''}`}>
        <ul className="list-unstyled">
          <li className="tooltip-area socicon discord">
            <a href="https://discord.gg/gUGqmt4Gqf" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faDiscord} />
                <p className="tooltip-text">Discord</p>
            </a>
          </li>
          <li className="tooltip-area socicon instagram">
            <a href="https://www.instagram.com/usyd_cybersoc/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} />
              <p className="tooltip-text">Instagram</p>
            </a>
          </li>
          <li className="tooltip-area socicon linkedin">
            <a href="https://www.linkedin.com/company/usyd-csec" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedinIn} />
              <p className="tooltip-text">Linkedin</p>
            </a>
          </li>
          <li className="tooltip-area socicon facebook">
            <a href="https://www.facebook.com/profile.php?id=61552628758945" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookF} />
              <p className="tooltip-text">Facebook</p>
            </a>
          </li>
          <li className="tooltip-area socicon usu">
            <a href="https://usu.edu.au/clubs/sydney-university-cyber-security-society/" target="_blank" rel="noopener noreferrer">
              <img className="image-icon" src={usuLogo} />
              <p className="tooltip-text">USU</p>
            </a>
          </li>
        </ul>
        <div className="main" onClick={show}>
          <span className="fixed-right">
            <FontAwesomeIcon icon={faSearchLocation} />
          </span>
        </div>
      </div>
  );
}

function Popup(props) {
    return (
      <Modal
        {...props}
        size="lg"
        centered
      >
        <Modal.Header closeButton className="bg-dark text-light">
          <Modal.Title id="contained-modal-title-vcenter">
            Acknowledgement of Country
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-light">
            <p>
                USYD Cybersecurity Society would like to acknowledge the Traditional Owners of Australia and recognise their continuing connection to land, water, and culture.
            </p>
            <p>
                We are currently on the land of the Gadigal people of the Eora Nation and pay our respects to their Elders, past, present, and emerging. We further acknowledge the Traditional Owners of the country on which you are on and pay respects to their Elders, past, present, and future.
            </p>
        </Modal.Body>
        <Modal.Footer className="bg-dark text-light">
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
}

function CountryAcknowledgementPopup() {
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        setModalShow(true);
    }, []);

    return (
        <Popup
            show={modalShow}
            onHide={() => setModalShow(false)}
        />
    );
}

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row className="home-row">
            <Col md={7} className="home-header">
{/*
	<h1 style = {{	fontSize: '3rem'}}>
                Welcome!{' '}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1> */}

              <h1 style = {{fontSize: '5rem', textAlign: 'left'}}>
                Sydney <br/> University <br/> Cyber Security <br/> Society
              </h1>

              <div style={{ padding: '50px 50px 0 50px', textAlign: 'left' }}>
                <Type />
              </div>
            </Col>

            <Col md={5} className="home-hacker-img-div">
              <img className="home-hacker-img" src={homeLogo} alt="Hacker" />
            </Col>
          </Row>
        </Container>
        <Container fluid id="acknowledgement">
            <CountryAcknowledgementPopup/>
        </Container>
        <Container fluid id="findus">
            <FindUsShortCut/>
        </Container>
        <Container fluid className="home-about-section" id="about">
          <Container>
            <Row className="home-about-us">
              <Col md={6} className="home-about-description">
                <h1 style={{ fontSize: '2.6em' }}>
                  ABOUT <span className="blue"> US! </span>
                </h1>
                <p className="home-about-body">
                  <i>
                    We are{' '}
                    <b className="blue">
                      Sydney University's first Cyber Security Society!
                    </b>{' '}
                    Our society is a dedicated community of individuals
                    passionate about navigating the ever-evolving landscape of
                    cybersecurity.
                  </i>
                  <br />
                  <br />
                  Incorporating engaging activities such as{' '}
                  <i>
                    <b className="blue">
                      Capture the Flag competitions and industry workshops,
                    </b>{' '}
                  </i>
                  our events are designed to address the growing imperative for
                  cyber literacy in today's digital landscape.{' '}
                  <b className="blue">
                    Our ultimate goal is to empower our members{' '}
                  </b>
                  to emerge as exemplary individuals capable of navigating and
                  excelling in the complex challenges of the cyber realm.
                </p>
              </Col>
              <Col md={6} className="myAvtar">
                <Tilt>
                  <img src={myImg} className="img-fluid" alt="avatar" />
                </Tilt>
              </Col>
            </Row>

            <Row className="home-about-sponsors">
              <Col
                md={8}
                className="home-about-description home-sponsors-description"
              >
                <h1 style={{ fontSize: '2.6em' }}>
                  OUR <span className="blue"> SPONSORS! </span>
                </h1>
                <p className="home-about-body">
                  <i>
                    We are grateful for the support of our sponsors, who play a
                    crucial role in fueling our mission to empower individuals
                    in the field of cybersecurity.
                  </i>
                  <br />
                  <br />
                  Our sponsors, including industry leaders like{' '}
                  <i>
                    <b className="blue">Dewaweb and Dewaguard,</b>{' '}
                  </i>
                  contribute to the success of our events and initiatives,
                  enabling us to provide valuable experiences and resources to
                  our members.
                  <br />
                  <br />
                  We believe in building strong partnerships with organizations
                  that share our commitment to fostering a knowledgeable and
                  skilled community in the cybersecurity domain.
                </p>
              </Col>

              {/* Sponsor Logos */}
              <Row className="sponsor-logos">
                <a
                  href="https://dewaweb.com"
                  style={{ width: 'unset' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={dewawebLogo}
                    className="sponsor-logo"
                    alt="Dewaweb Logo"
                  />
                </a>
                <a
                  href="https://dewaguard.com"
                  style={{ width: 'unset' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={dewaguardLogo}
                    className="sponsor-logo"
                    alt="Dewaguard Logo"
                  />
                </a>
                {/* Add more sponsor logos as needed */}
              </Row>
            </Row>

            <Row>
              <Col md={12} className="home-about-social">
                <h1>FIND US ON</h1>
                <p>
                  Feel free to <span className="blue">connect </span>with us
                  through any of these channels
                </p>
                <ul className="home-about-social-links">
                  <li className="social-icons">
                    <a
                      href="https://discord.gg/gUGqmt4Gqf"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour home-social-icons"
                    >
                      <FaDiscord />
                    </a>
                  </li>
                  <li className="social-icons">
                    <a
                      href="https://www.facebook.com/profile.php?id=61552628758945"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour home-social-icons"
                    >
                      <FaFacebookF />
                    </a>
                  </li>
                  <li className="social-icons">
                    <a
                      href="https://www.linkedin.com/company/usyd-csec"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour home-social-icons"
                    >
                      <FaLinkedinIn />
                    </a>
                  </li>
                  <li className="social-icons">
                    <a
                      href="https://www.instagram.com/usyd_cybersoc/"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour home-social-icons"
                    >
                      <AiFillInstagram />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Container>
      </Container>
    </section>
  );

}

export default Home;
